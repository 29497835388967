var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:c-col-4 md:c-col-11 lg:c-col-10"},[_c('div',{staticClass:"c-row"},[(_vm.email && _vm.id && _vm.token)?_c('div',{staticClass:"c-col-full md:c-col ui-min-w-0 md:c-d-flex md:ui-flex-col"},[_c('div',{staticClass:"c-text-subtitle c-text-gray-400"},[_vm._v(" Atualize a sua Senha ")]),_c('div',{staticClass:"c-text-b2 c-text-gray-300 ui-mb-4 md:ui-flex-grow"},[_vm._v(" para completar o seu cadastro ")]),_c('p-form',{on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',{staticClass:"c-row ui-mb-4"},[_c('validation-provider',{staticClass:"c-col ui-mb-4",attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('p-input',{attrs:{"label":"Email*","name":"email","disabled":true,"error-message":errors[0],"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('div',{staticClass:"c-row ui-mb-4"},[_c('validation-provider',{staticClass:"c-col-2 sm:c-col ui-mb-4",attrs:{"rules":"required|min:8","name":"Senha","vid":"password","custom-messages":{
              required: 'Senha é obrigatória',
              min: 'A senha é muito curta'
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{key:"passwordField",attrs:{"data-cy":"passwordField","label":"Senha*","name":"password","error-message":errors[0],"icon-info":"info"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}})]}}],null,true)}),_c('validation-provider',{staticClass:"c-col-2 sm:c-col",attrs:{"rules":"required|confirmed:password","name":"Confirmação de senha","custom-messages":{
              required: 'Confirmação de senha é obrigatória',
              confirmed: 'Senhas não são iguais'
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{key:"confirmPasswordField",attrs:{"data-cy":"confirmPasswordField","label":"Confirmar senha*","name":"confirm-password","error-message":errors[0]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"confirmPassword"}})]}}],null,true)})],1),_c('c-button',{staticClass:"ui-w-full",attrs:{"disabled":invalid,"data-cy":"registerButton","size":"lg","type":"submit","loading":_vm.isFormSubmitting}},[_vm._v(" Salvar "),_c('icon',{staticClass:"c-icon--right",attrs:{"name":"next"}})],1)]}}],null,false,750169246)})],1):_c('div',{staticClass:"c-col-full md:c-col ui-min-w-0 md:c-d-flex md:ui-flex-col"},[_c('div',{staticClass:"c-text-subtitle c-text-gray-400"},[_vm._v(" Dados inválidos ")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-col-full md:c-col-4 ui-min-w-0 ui-mx-4 c-d-flex c-vertical-end c-horizontal-center md:c-horizontal-start",attrs:{"id":"image-wrapper"}},[_c('img',{staticClass:"ui-mt-4 ui--mb-8",attrs:{"src":require("@/assets/images/confirm-email-illustration.svg"),"alt":"Imagem de confirmação de email"}})])
}]

export { render, staticRenderFns }