<template>
  <div class="sm:c-col-4 md:c-col-11 lg:c-col-10">
    <div
      class="c-row"
    >
      <div
        v-if="email && id && token"
        class="c-col-full md:c-col ui-min-w-0 md:c-d-flex md:ui-flex-col"
      >
        <!-- Título -->
        <div class="c-text-subtitle c-text-gray-400">
          Atualize a sua Senha
        </div>

        <!-- Mensagem -->
        <div class="c-text-b2 c-text-gray-300 ui-mb-4 md:ui-flex-grow">
          para completar o seu cadastro
        </div>

        <p-form
          @submit="onSubmit"
          v-slot="{ invalid }"
        >
          <!-- Email -->
          <div class="c-row ui-mb-4">
            <validation-provider
              class="c-col ui-mb-4"
              rules="required|email"
              v-slot="{ errors }"
              name="Email"
            >
              <p-input
                v-model="email"
                label="Email*"
                name="email"
                :disabled="true"
                :error-message="errors[0]"
                type="email"
              />
            </validation-provider>
          </div>

          <div class="c-row ui-mb-4">
            <validation-provider
              class="c-col-2 sm:c-col ui-mb-4"
              rules="required|min:8"
              name="Senha"
              vid="password"
              v-slot="{ errors }"
              :custom-messages="{
                required: 'Senha é obrigatória',
                min: 'A senha é muito curta'
              }"
            >
              <input-password
                data-cy="passwordField"
                key="passwordField"
                label="Senha*"
                name="password"
                v-model.trim="password"
                :error-message="errors[0]"
                icon-info="info"
              />
            </validation-provider>

            <validation-provider
              class="c-col-2 sm:c-col"
              rules="required|confirmed:password"
              name="Confirmação de senha"
              v-slot="{ errors }"
              :custom-messages="{
                required: 'Confirmação de senha é obrigatória',
                confirmed: 'Senhas não são iguais'
              }"
            >
              <input-password
                data-cy="confirmPasswordField"
                key="confirmPasswordField"
                label="Confirmar senha*"
                name="confirm-password"
                v-model.trim="confirmPassword"
                :error-message="errors[0]"
              />
            </validation-provider>
          </div>

          <!-- Botão Continuar -->
          <c-button
            :disabled="invalid"
            data-cy="registerButton"
            size="lg"
            type="submit"
            class="ui-w-full"
            :loading="isFormSubmitting"
          >
            Salvar
            <icon
              name="next"
              class="c-icon--right"
            />
          </c-button>
        </p-form>
      </div>
      <div
        v-else
        class="c-col-full md:c-col ui-min-w-0 md:c-d-flex md:ui-flex-col"
      >
        <div class="c-text-subtitle c-text-gray-400">
          Dados inválidos
        </div>
      </div>

      <!-- Ilustração -->
      <div
        id="image-wrapper"
        class="c-col-full md:c-col-4 ui-min-w-0 ui-mx-4 c-d-flex c-vertical-end c-horizontal-center md:c-horizontal-start"
      >
        <img
          class="ui-mt-4 ui--mb-8"
          src="@/assets/images/confirm-email-illustration.svg"
          alt="Imagem de confirmação de email"
        >
      </div>
    </div>
  </div>
</template>
<script>
import PForm from '@/components/p-form';
import { ValidationProvider } from 'vee-validate';
import PInput from '@/components/p-input';
import InputPassword from '@/components/input-password';
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'CadastroAdicionaSenha',
  components: {
    PForm,
    PInput,
    ValidationProvider,
    InputPassword,
    CButton,
    Icon,
  },
  data () {
    return {
      id: this.$route.query.id,
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: '',
      confirmPassword: '',
      isFormSubmitting: false,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isFormSubmitting = true;
        await this.$services.accounts.resetPassword(this.token, this.password, this.id);

        this.$router.push({ name: 'login', query: { email: this.email } });
      } catch (err) {
        console.error(err);
        this.$toast.show({
          type: 'error',
          text: 'Erro ao atualizar senha',
          timeout: 3000,
        });
      } finally {
        this.isFormSubmitting = false;
      }

    },
  },
};
</script>
