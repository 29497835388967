<template>
  <div
    style="min-height: 100%;"
    class="c-d-flex ui-flex-col c-bg-white"
  >
    <!-- Content -->
    <div class="ui-flex-1 c-d-flex c-center">
      <div class="ui-w-full c-row c-horizontal-center">
        <router-view class="c-col-full c-bg-white ui-overflow-hidden ui-px-4 md:ui-px-8 ui-py-2" />
      </div>
    </div>
  </div>
</template>

<script>

export default {};
</script>

<style scoped lang="scss">

</style>
