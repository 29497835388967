<template>
  <div>
    <!-- Título pedido -->
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div class="c-d-flex c-vertical-end ui-mb-4 ui-justify-between">
          <c-skeleton-block
            v-if="loading"
            width="12rem"
          />
          <span
            class="c-text-h3"
            v-else
          > Pedido {{ order.orderNumber }} </span>

          <div
            v-if="loading"
            class="c-d-flex ui-h-4 ui-space-x-2"
          >
            <c-skeleton-circle size="1rem" />
            <c-skeleton-block width="6rem" />
          </div>
        </div>

        <!-- Card de detalhes -->
        <div class="ui-mb-8">
          <status-card
            :loading="loading"
            :value="order.status"
          >
            <div class="ui-p-6 c-bg-white">
              <!-- Vertical -->
              <c-skeleton-block
                v-if="loading"
                height="1rem"
                width="8rem"
              />
              <div
                v-if="!loading && hasVertical"
                class="c-text-gray c-text-b2 ui-flex ui-flex-row"
              >
                {{ order.titleVertical }}
                <div class="ui-font-bold ui-mx-2 c-text-primary">
                  |
                </div>
                <div class="ui-font-semibold c-text-primary">
                  Produtos
                </div>
              </div>

              <!-- Loader do nome do produto -->
              <div
                v-if="loading"
                class="c-d-flex c-horizontal-center ui-flex-col ui-mt-2"
              >
                <div class="c-d-flex c-vertical-center">
                  <div
                    class="ui-w-1 ui-h-1 ui-mr-2 ui-rounded-lg c-bg-gray-200"
                  />
                  <c-skeleton-block
                    width="16rem"
                    height="1rem"
                  />
                </div>

                <c-skeleton-block
                  class="ui-mt-2 ui-ml-3"
                  width="8rem"
                  height="1rem"
                />
              </div>
              <div v-else>
                <!-- Nome do produto -->
                <div
                  class="c-d-flex c-horizontal-center ui-flex-col ui-mt-2"
                  v-for="item of order.orderItems"
                  :key="item.id"
                >
                  <div class="c-d-flex c-vertical-center ui-flex-wrap">
                    <span class="c-text-subtitle">{{ item.description }}</span>
                    <c-pill
                      v-if="order.isDowngraded(item)"
                      outline
                      class="ui-ml-2"
                      label="RENOVAÇÃO EFETUADA"
                      color="var(--c-color-gray)"
                    />
                    <c-pill
                      v-else-if="order.isDowngrade(item)"
                      class="ui-ml-2"
                      label="RENOVAÇÃO"
                    />
                    <c-pill
                      v-else-if="order.isRenovadoAntecipadamente(item)"
                      outline
                      class="ui-ml-2"
                      label="RENOVAÇÃO EFETUADA"
                      color="var(--c-color-gray)"
                    />
                    <c-pill
                      v-else-if="order.isRenovacaoAntecipada(item)"
                      class="ui-ml-2"
                      label="RENOVAÇÃO ANTECIPADA"
                    />
                    <c-pill
                      v-else-if="order.isUpgraded(item)"
                      outline
                      class="ui-ml-2"
                      label="UPGRADE EFETUADO"
                      color="var(--c-color-gray)"
                    />
                    <c-pill
                      v-else-if="order.isUpgrade(item)"
                      class="ui-ml-2"
                      label="UPGRADE"
                    />
                  </div>
                  <div class="ui-flex ui-w-full">
                    <div
                      class="c-d-flex c-vertical-center ui-gap-3"
                      v-if="item.expiresAt"
                    >
                      <span
                        class="c-text-b2 c-text-gray"
                      >Vigência: {{ item.expiresAt | date }}</span>
                    </div>
                    <div
                      v-if="showDownloads"
                      class="ui-ml-2"
                    >
                      <span
                        class="c-text-b2 c-text-gray"
                      >- Download Vídeos:</span>
                      <span
                        class="ui-font-bold"
                        v-if="!userDownloadLoading"
                      >
                        {{ item.downloads?.total_video_downloads || 0 }}
                      </span>
                      <c-skeleton-block
                        v-else
                        width="2rem"
                        height="10px"
                        style="background-color: blue; display: inline-table;"
                      />
                      <span
                        class="c-text-b2 c-text-gray ui-ml-2"
                      >Download PDFs:</span>
                      <span
                        class="ui-font-bold"
                        v-if="!userDownloadLoading"
                      >
                        {{ item.downloads?.total_pdf_downloads || 0 }}
                      </span>
                      <c-skeleton-block
                        v-else
                        width="2rem"
                        height="10px"
                        style="background-color: blue; display: inline-table;"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="hasBonus">
                <separator />

                <div
                  v-if="loading"
                  class="c-d-flex c-horizontal-center ui-flex-col ui-mt-2"
                >
                  <div class="c-d-flex c-vertical-center">
                    <div
                      class="ui-w-1 ui-h-1 ui-mr-2 ui-rounded-lg c-bg-gray-200"
                    />
                    <c-skeleton-block
                      width="16rem"
                      height="1rem"
                    />
                  </div>

                  <c-skeleton-block
                    class="ui-mt-2 ui-ml-3"
                    width="8rem"
                    height="1rem"
                  />
                </div>
                <div v-else>
                  <div class="c-text-primary ui-mt-4">
                    <span class="ui-font-bold">|</span> Bônus
                  </div>
                  <!-- Nome do produto -->
                  <div
                    class="c-d-flex c-horizontal-center ui-flex-col"
                    v-for="bonus of bonuses"
                    :key="bonus.id"
                  >
                    <div class="c-d-flex c-vertical-center ui-mb-3">
                      <span class="c-text-input ui-font-semibold">{{
                        bonus.name
                      }}</span>
                    </div>
                    <div
                      class="c-d-flex c-vertical-center"
                      v-if="bonus.expiresAt"
                    >
                      <span
                        class="c-text-b2 c-text-gray"
                      >Vigência: {{ bonus.expiresAt | date }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <separator />

              <!-- Detalhes do pedido -->
              <div class="c-row ui-relative ui-mb-2">
                <!-- Coluna da esquerda -->
                <div class="c-col-full sm:c-col-1/4">
                  <!-- Valor total -->
                  <div class="c-row ui-mb-2">
                    <div class="c-col-full">
                      <c-skeleton-block
                        v-if="loading"
                        width="4rem"
                        height="1rem"
                      />
                      <div
                        v-else
                        class="c-text-b2 c-text-gray"
                      >
                        Valor total
                      </div>

                      <c-skeleton-block
                        v-if="loading"
                        class="ui-mt-2"
                        width="6rem"
                        height="1rem"
                      />
                      <div
                        v-else
                        class="c-text-b1 ui-flex ui-items-center"
                      >
                        {{ order.amount | currency }}

                        <c-link
                          v-if="isPix && !loading && !isPaid"
                          @click="showModalPixQrCode"
                          class="--c-color-primary c-d-flex ui-items-center"
                          style="gap: 5px"
                        >
                          Visualizar pix
                          <span>
                            <c-icon
                              name="money-dollar-circle"
                              size="large"
                            />
                          </span>
                        </c-link>

                        <c-link
                          v-if="isBoleto && !loading && !isPaid"
                          :href="order.boletoPdfUrl"
                          target="_blank"
                          class="--c-color-primary c-d-flex ui-items-center"
                          style="gap: 5px"
                        >
                          Visualizar boleto
                          <span>
                            <c-icon
                              name="barcode"
                              size="large"
                            />
                          </span>
                        </c-link>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Coluna da direita -->
                <div class="c-col-full sm:c-col-1/4 ui-mb-2">
                  <!-- Contratação -->
                  <div
                    class="c-row ui-mb-2"
                    v-if="!!order.contractDate"
                  >
                    <div class="c-col-full">
                      <c-skeleton-block
                        v-if="loading"
                        width="4rem"
                        height="1rem"
                      />
                      <div
                        v-else
                        class="c-text-b2 c-text-gray"
                      >
                        Contratação
                      </div>

                      <c-skeleton-block
                        v-if="loading"
                        class="ui-mt-2"
                        width="6rem"
                        height="1rem"
                      />
                      <div
                        v-else
                        class="c-text-b1"
                      >
                        {{ order.contractDate | date }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="c-col-full sm:c-col-1/4 ui-mb-2"
                >
                  <div class="c-row ui-mb-2">
                    <div class="c-col-full">
                      <c-skeleton-block
                        v-if="loading"
                        class="ui-mt-2"
                        width="6rem"
                        height="1rem"
                      />
                      <div
                        v-else
                        class="c-text-b1"
                      >
                        <c-button
                          v-if="showSearchNFe"
                          color="primary"
                          :loading="pollingRunning"
                          outline
                          @click="() => searchNFe('start')"
                        >
                          Buscar Nota fiscal
                          <c-icon
                            class="c-icon--right ui-mb-1"
                            name="search"
                            size="medium"
                            color="inherit"
                          />
                        </c-button>
                        <c-button
                          v-if="showDownloadNFeProduct"
                          color="primary"
                          outline
                          @click="() => downloadNFe('product')"
                        >
                          NF Produto
                          <c-icon
                            class="c-icon--right ui-mb-1"
                            name="download"
                            size="medium"
                            color="inherit"
                          />
                        </c-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="c-col-full sm:c-col-1/4 ui-mb-2"
                >
                  <div class="c-row ui-mb-2">
                    <div class="c-col-full">
                      <c-skeleton-block
                        v-if="loading"
                        class="ui-mt-2"
                        width="6rem"
                        height="1rem"
                      />
                      <div
                        v-else
                        class="c-text-b1"
                      >
                        <c-button
                          v-if="showDownloadNFeService"
                          color="primary"
                          outline
                          @click="() => downloadNFe('service')"
                        >
                          NF Serviço
                          <c-icon
                            class="c-icon--right ui-mb-1"
                            name="download"
                            size="medium"
                            color="inherit"
                          />
                        </c-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="order.status === 'paid'"
              >
                <div
                  v-if="!showDownloads"
                  class="c-d-flex c-horizontal-center ui-flex-col ui-mt-2"
                >
                  <c-link
                    class="c-text-gray-300 c-d-flex"
                    component="button"
                    @click="getUserDownload()"
                  >
                    <icon
                      name="Search"
                      color="var(--c-color-gray-300)"
                      width="18"
                    />
                    Consultar downloads realizados
                  </c-link>
                </div>
                <div
                  v-else
                  style="background-color: lightyellow; padding: 15px;"
                >
                  <span class="c-text-subtitle">Filtrar Downloads</span>
                  <div class="ui-flex ui-justify-start ui-items-center ui-gap-2">
                    <c-input
                      style="width: 160px;"
                      type="date"
                      label="Data inicial"
                      v-model="downloadFilter.fromDate"
                    />
                    <c-input
                      style="width: 160px;"
                      type="date"
                      label="Data final"
                      v-model="downloadFilter.toDate"
                    />
                    <c-button
                      class="ui-w-60 ui-mb-4 sm:ui-mb-0"
                      :loading="userDownloadLoading"
                      outline
                      @click="getUserDownload"
                    >
                      Filtrar
                    </c-button>
                  </div>
                </div>
              </div>
            </div>
          </status-card>
        </div>

        <template v-if="order.recurrences && order.recurrences.length > 0">
          <div class="c-text-h3 ui-mb-4">
            Pagamentos
          </div>
          <order-recurrence
            @change-order-by="onUpdateOrderBy"
            :recurrences="order.recurrences"
          />
        </template>
      </div>
    </div>

    <!-- Botões -->
    <div class="c-row c-horizontal-center ui-mt-10">
      <!-- Voltar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <router-link :to="{ name: 'minhas-compras' }">
          <c-button
            class="ui-w-full ui-mb-4 sm:ui-mb-0"
            outline
          >
            Voltar
          </c-button>
        </router-link>
      </div>
      <!-- Cancelar compra -->
      <div
        v-if="order.isCancellable"
        class="c-col-full sm:c-col-1/4 lg:c-col-2"
      >
        <a
          :href="order.supportUrl"
          target="_blank"
        >
          <c-button
            outline
            size="md"
            class="ui-w-full"
          >
            Cancelar compra
          </c-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CButton,
  CIcon,
  CLink,
  CSkeletonBlock,
  CSkeletonCircle,
  CPill,
  Icon,
} from '@estrategiahq/coruja-web-ui';
import CInput from '@/components/c-input';
import { User } from '@/models/user';
import { formatToDecimalCurrency } from '@/utils/decimal';
import { formatDateBR } from '@/utils/date';
import { resourceNotFoundError } from '@/utils/errors';
import Separator from '@/components/separator';
import StatusCard from './components/status-card';
import { OrderStoreKeys } from '@/store/modules/order';
import QrCodeModal from '@/modals/qr-code';
import { STATUS } from '@/consts';

import OrderRecurrence from './components/order-recurrence.vue';

const DELAY_POLLING_ATTEMPT = 5000;
const MAX_POLLING_ATTEMPTS = 60000 * 3 / DELAY_POLLING_ATTEMPT; // 36 tentativas (3 minuto)
const NF_MSGS = {
  both: 'Notas fiscais disponíveis para download',
  error: 'Não foi possível obter a nota fiscal, tente novamente mais tarde.',
  product: 'NF Produto disponível para download',
  service: 'NF Serviço disponível para download',
  start: 'Nota fiscal solicitada! O download ficará disponível em alguns instantes...',
};

export default {
  name: 'PedidoDetalhesPage',
  components: {
    CButton,
    StatusCard,
    Separator,
    CLink,
    CSkeletonBlock,
    CSkeletonCircle,
    CIcon,
    CPill,
    OrderRecurrence,
    CInput,
    Icon,
  },
  filters: {
    currency: formatToDecimalCurrency,
    date: formatDateBR,
    formatProductPrice(amount_paid) {
      return amount_paid > 0
        ? formatToDecimalCurrency(amount_paid)
        : 'Gratuito';
    },
  },
  props: {
    user: {
      type: User,
      required: true,
    },
  },
  data: () => ({
    orderNumber: null,
    vertical: null,
    downloadFilter: {
      fromDate: '',
      toDate: '',
    },
    showDownloads: false,
    urlNFeProduct: '',
    urlNFeService: '',
    showSearchNFe: false,
    showDownloadNFeProduct: false,
    showDownloadNFeService: false,
    pollingRunning: false,
  }),
  async mounted() {
    this.orderNumber = this.$route.params.orderNumber;
    this.vertical = this.$route.params.vertical;

    if (!(this.orderNumber && this.vertical)) {
      return this.$router.push({ name: 'minhas-compras' });
    }

    await this.getOrder();
    await this.getNFe();
  },
  computed: {
    ...mapGetters({
      loading: OrderStoreKeys.GET_REQUEST_LOADING,
      order: OrderStoreKeys.GET_CURRENT_ORDER,
      userDownloadLoading: OrderStoreKeys.GET_USER_DOWNLOADS_LOADING,
    }),
    isBoleto() {
      return !!this.order.boletoPdfUrl;
    },
    isPix() {
      return this.order.paymentMethod === 'pix';
    },
    hasBonus() {
      return !!this.order.bonuses.length;
    },
    hasVertical() {
      return !!this.order.titleVertical;
    },
    bonuses() {
      return this.order.bonuses.filter(
        (b) =>
          !b.isDiscountBonus ||
          this.order.orderItems.some((oi) => oi.id === b.id),
      );
    },
    pixHasExpired() {
      if (this.order.pixQrCodeExpiresAt) {
        return (
          new Date().getTime() -
            new Date(this.order.pixQrCodeExpiresAt).getTime() >
          0
        );
      }
      return !this.order.pixQrCodeUrl;
    },
    isPaid() {
      return this.order.status === STATUS.PAID;
    },
    isNfeProductDownload() {
      return this.order.is_nfe_product;
    },
    isNfeServiceDownload() {
      return this.order.is_nfe_service;
    },
  },
  methods: {
    async getOrder() {
      try {
        await this.$store.dispatch(OrderStoreKeys.ACTION_FETCH_CURRENT_ORDER, {
          orderNumber: this.orderNumber,
          vertical: this.vertical,
        });
      } catch (e) {
        if (e === resourceNotFoundError) {
          this.$router.push({ name: 'minhas-compras' });
        } else {
          throw e;
        }
      }
    },
    downloadNFe (nfeType) {
      const fileName = `Nota fiscal - Pedido ${this.order.order_number}.pdf`;
      if (nfeType === 'service') this.triggerDownload(this.urlNFeService, fileName);
      else this.triggerDownload(this.urlNFeProduct, fileName);
    },
    triggerDownload (url, name) {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';
      anchor.download = name;
      anchor.click();
    },
    showModalPixQrCode() {
      if (this.pixHasExpired) {
        return this.handleExpiredPix();
      }
      this.$modal.show({
        component: QrCodeModal,
        overlayOpacity: 0.5,
        props: {
          pixQrCodeUrl: this.order.pixQrCodeUrl,
          pixQrCode: this.order.pixQrCode,
        },
      });
    },
    handleExpiredPix() {
      this.$toast.show({
        type: 'warning',
        text: 'O código Pix expirou! Por favor, realize o processo de compra novamente.',
      });
      this.redirectToCardWithProduct();
    },
    redirectToCardWithProduct() {
      const vertical = ((name) => {
        if (name === 'carreiras-juridicas') {
          return 'cj';
        }
        if (name === 'medicina') {
          return 'med';
        }
        return name;
      })(this.$route.params.vertical);
      if (this.order.itemsSlugsForCartUrl.length > 0) {
        const url = this.order.itemsSlugsForCartUrl.reduce(
          (acc, curr) => acc + 'produto=' + curr + '&',
          `https://${vertical}.${this.$env.VUE_APP_DOMAIN}/cursos/carrinho/?`,
        );
        window.open(url);
      }
    },
    onUpdateOrderBy(value) {
      const payload = { sort: value.sort, prop: value.prop };
      this.$store.dispatch(
        OrderStoreKeys.ACTION_SORT_ORDER_RECURRENCE,
        payload,
      );
    },
    async getUserDownload() {
      this.showDownloads = true;
      if (this.downloadFilter.fromDate && this.downloadFilter.toDate) {
        if (new Date(this.downloadFilter.fromDate) > new Date(this.downloadFilter.toDate)) {
          return this.$toast.show({
            type: 'warning',
            text: 'A data inicial não pode ser maior que a data final',
          });
        }
      }
      await this.$store.dispatch(OrderStoreKeys.ACTION_FETCH_GET_USER_DOWNLOADS, {
        orderNumber: this.orderNumber,
        vertical: this.order.vertical,
        fromDate: this.downloadFilter.fromDate,
        toDate: this.downloadFilter.toDate,
      });
    },
    async searchNFe (type = 'both', attempt = 0) {
      this.pollingRunning = true;

      if (attempt === 0) this.showToastByType('start');

      let nfe = null;
      try {
        nfe = await this.$services.bff.searchOne({
          orderId: this.order.id,
          status: 'paid',
          vertical: this.order.vertical,
        });
      } catch (err) {
        console.error(err);
      }

      if (!nfe) {
        nfe = await this.$services.bff.createNFe(this.order.id, this.order.vertical);
      }

      if (nfe?.url_nfe_servico) {
        this.urlNFeService = nfe.url_nfe_servico;
      }

      if (nfe?.url_nfe_produto) {
        this.urlNFeProduct = nfe.url_nfe_produto;
      }

      if (!this.verifyShouldContinuePolling(type)) {
        this.showSearchNFe = false;
        this.pollingRunning = false;
        return;
      }

      if (attempt === 0) {
        await this.$services.bff.getMissingUrl(nfe.id, this.order.vertical);
      }

      if (attempt >= MAX_POLLING_ATTEMPTS) {
        this.showToastByType('error');
        this.pollingRunning = false;
        return;
      }

      await new Promise(resolve => setTimeout(resolve, DELAY_POLLING_ATTEMPT));
      return this.searchNFe(type, attempt + 1);
    },
    showToastByType (type) {
      let msg = '';
      let action = 'success';
      switch (type) {
      case 'error': msg = NF_MSGS.error; action = 'error'; break;
      case 'start': msg = NF_MSGS.start; break;
      case 'product': msg = NF_MSGS.product; break;
      case 'service': msg = NF_MSGS.service; break;
      default: msg = NF_MSGS.both;
      }

      return this.$toast.show({ type: action, text: msg, timeout: 10000 });
    },
    verifyShouldContinuePolling (type) {
      if (this.urlNFeProduct && this.urlNFeService) {
        this.showDownloadNFeProduct = true;
        this.showDownloadNFeService = true;
        this.showToastByType(type);
        return false;
      }
      if (type === 'product' && this.urlNFeProduct) {
        this.showDownloadNFeProduct = true;
        this.showToastByType(type);
        return false;
      }
      if (type === 'service' && this.urlNFeService) {
        this.showDownloadNFeService = true;
        this.showToastByType(type);
        return false;
      }

      return true;
    },
    async getNFe () {
      const nfe = await this.$services.bff.searchOne({
        orderId: this.order.id,
        status: 'paid',
        vertical: this.order.vertical,
      });

      if (nfe?.url_nfe_produto) {
        this.urlNFeProduct = nfe.url_nfe_produto;
        this.showDownloadNFeProduct = true;
      }

      if (nfe?.url_nfe_servico) {
        this.urlNFeService = nfe.url_nfe_servico;
        this.showDownloadNFeService = true;
      }

      if (this.order.status === 'paid' && this.order.amount > 0 && !this.urlNFeProduct && !this.urlNFeService) {
        this.showSearchNFe = true;
      }
    },
  },
};
</script>
