import { Order } from '@/models/order';

const prefix = 'order';
//mutations
const SET_LOGGED_USER_FAILED_RECURRENCES = `${prefix}:setLoggedUserFailedRecurrences`;
const SET_LOGGED_USER_ORDERS = `${prefix}:setLoggedUserOrders`;
const SET_CURRENT_ORDER = `${prefix}:setCurrentOrder`;
const SET_REQUEST_LOADING = `${prefix}:setRequestLoading`;
const SET_CREDIT_CARDS = `${prefix}:setCreditCards`;
const SET_CURRENT_ORDER_NFE = `${prefix}:setCurrentOrderNfe`;
const REMOVE_CREDIT_CARD = `${prefix}:removeCreditCard`;
const SORT_ORDER_RECURRENCES = `${prefix}:sortOrderRecurrences`;
const SET_USER_DOWNLOADS_LOADING = `${prefix}:setUserDownloadsLoading`;
// actions
const ACTION_FETCH_LOGGED_USER_FAILED_RECURRENCES = `${prefix}:fetchLoggedUserFailedRecurrences`;
const ACTION_FETCH_LOGGED_USER_ORDERS = `${prefix}:fetchLoggedUserOrders`;
const ACTION_FETCH_CURRENT_ORDER = `${prefix}:fetchCurrentOrder`;
const ACTION_FETCH_CREDIT_CARDS = `${prefix}:fetchCreditCards`;
const ACTION_CREATE_CREDIT_CARD = `${prefix}:createCreditCard`;
const ACTION_DELETE_CREDIT_CARD = `${prefix}:deleteCreditCard`;
const ACTION_SORT_ORDER_RECURRENCE = `${prefix}:sortOrderRecurrence`;
const ACTION_FETCH_CURRENT_ORDER_NFE = `${prefix}:fetchCurrentOrderNfe`;
const ACTION_FETCH_GET_USER_DOWNLOADS = `${prefix}:fetchGetUserDownloads`;

// getters
const GET_LOGGED_USER_FAILED_RECURRENCES = `${prefix}:getLoggedUserFailedRecurrences`;
const GET_LOGGED_USER_ORDERS = `${prefix}:getLoggedUserOrders`;
const GET_CURRENT_ORDER = `${prefix}:getCurrentOrder`;
const GET_REQUEST_LOADING = `${prefix}:getRequestLoading`;
const GET_CREDIT_CARDS = `${prefix}:getCreditCards`;
const GET_USER_DOWNLOADS_LOADING = `${prefix}:getUserDownloadsLoading`;

const state = {
  creditCards: [],
  failedRecurrences: [],
  order: new Order(),
  orders: [],
  requestsCounter: 0,
  requestsCounterDownloads: 0,
};

const mutations = {
  [SET_LOGGED_USER_FAILED_RECURRENCES](state, failedRecurrences) {
    state.failedRecurrences = failedRecurrences;
  },
  [SET_LOGGED_USER_ORDERS](state, orders) {
    state.orders = orders;
  },
  [SET_REQUEST_LOADING](state, value) {
    if (value) {
      state.requestsCounter++;
    } else {
      state.requestsCounter--;
    }
  },
  [SET_CURRENT_ORDER](state, value) {
    state.order = value;
  },
  [SET_CURRENT_ORDER_NFE](state, value) {
    state.order.nfe_pdf = value;
  },
  [SET_CREDIT_CARDS](state, value) {
    state.creditCards = value;
  },
  [REMOVE_CREDIT_CARD](state, cardId) {
    const index = state.creditCards.findIndex(cc => cc.id === cardId);
    state.creditCards.splice(index, 1);
  },
  [SORT_ORDER_RECURRENCES](state, { sort, prop } ) {
    const direction = sort === 'ASC' ? 1 : -1;

    state.order.recurrences = state.order.recurrences.sort((a, b) => a[prop].localeCompare(b[prop]) * direction);
  },
  [SET_USER_DOWNLOADS_LOADING](state, value) {
    if (value) {
      state.requestsCounterDownloads++;
    } else {
      state.requestsCounterDownloads--;
    }
  },
};

const createActions = (bffService) => ({
  [ACTION_FETCH_LOGGED_USER_FAILED_RECURRENCES]({ commit }) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.getFailedRecurrences()
      .then(arr => commit(SET_LOGGED_USER_FAILED_RECURRENCES, arr))
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_FETCH_LOGGED_USER_ORDERS]({ commit }) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.getPurchaseHistory()
      .then(orders => commit(SET_LOGGED_USER_ORDERS, orders))
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_FETCH_CURRENT_ORDER]({ commit }, { orderNumber, vertical }) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.getOrderDetails(orderNumber, vertical)
      .then(order => commit(SET_CURRENT_ORDER, order))
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_FETCH_CREDIT_CARDS]({ commit }) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.getCreditCards()
      .then(data => commit(SET_CREDIT_CARDS, data))
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_DELETE_CREDIT_CARD]({ commit }, cardId) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.deleteCreditCard(cardId)
      .then(() => commit(REMOVE_CREDIT_CARD, cardId))
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_CREATE_CREDIT_CARD]({ commit }, payload) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.createCreditCard(payload)
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_SORT_ORDER_RECURRENCE]({ commit }, value) {
    commit(SORT_ORDER_RECURRENCES, value);
  },
  [ACTION_FETCH_CURRENT_ORDER_NFE]({ commit }, { isProduct, orderNumber, vertical }) {
    commit(SET_REQUEST_LOADING, true);
    return bffService.getOrderNfe(isProduct, orderNumber, vertical)
      .then(order => commit(SET_CURRENT_ORDER_NFE, order))
      .finally(() => commit(SET_REQUEST_LOADING, false));
  },
  [ACTION_FETCH_GET_USER_DOWNLOADS]({ commit }, { orderNumber, vertical, fromDate, toDate }) {
    commit(SET_USER_DOWNLOADS_LOADING, true);
    return bffService.getUserDownloads(orderNumber, vertical, fromDate, toDate)
      .then(data => {
        const order = state.order;
        if (order?.orderItems) {
          const updatedItems = order.orderItems.map((item) => ({
            ...item,
            downloads: data.find((d) => d.product_id === item.id) || { total_video_downloads: 0, total_pdf_downloads: 0 },
          }));
          order.orderItems = updatedItems;
        }
        return commit(SET_CURRENT_ORDER, order);
      })
      .finally(() => commit(SET_USER_DOWNLOADS_LOADING, false));
  },
});

const getters = {
  [GET_LOGGED_USER_FAILED_RECURRENCES](state) {
    return state.failedRecurrences;
  },
  [GET_LOGGED_USER_ORDERS](state) {
    return state.orders;
  },
  [GET_CREDIT_CARDS](state) {
    return state.creditCards;
  },
  [GET_REQUEST_LOADING](state) {
    return state.requestsCounter > 0;
  },
  [GET_CURRENT_ORDER](state) {
    return state.order;
  },
  [GET_USER_DOWNLOADS_LOADING](state) {
    return state.requestsCounterDownloads > 0;
  },
};

export const OrderStoreKeys = {
  ACTION_CREATE_CREDIT_CARD,
  ACTION_DELETE_CREDIT_CARD,
  ACTION_FETCH_CREDIT_CARDS,
  ACTION_FETCH_CURRENT_ORDER,
  ACTION_SORT_ORDER_RECURRENCE,
  ACTION_FETCH_LOGGED_USER_FAILED_RECURRENCES,
  ACTION_FETCH_LOGGED_USER_ORDERS,
  ACTION_FETCH_CURRENT_ORDER_NFE,
  ACTION_FETCH_GET_USER_DOWNLOADS,
  GET_CREDIT_CARDS,
  GET_CURRENT_ORDER,
  GET_LOGGED_USER_FAILED_RECURRENCES,
  GET_LOGGED_USER_ORDERS,
  GET_REQUEST_LOADING,
  GET_USER_DOWNLOADS_LOADING,
};

export const createBffStore = (bffService) => ({
  state,
  mutations,
  actions: createActions(bffService),
  getters,
});
