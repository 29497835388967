<template>
  <PLogin
    v-if="activeForm === 'login'"
    :prevent-redirection="true"
    @logged-in="handleLoggedIn"
  >
    <template #cadastro>
      <span
        class="c-text-hyperlink ui-cursor-pointer"
        @click="activeForm = 'create-form'"
      >
        Faça o cadastro
      </span>
    </template>
    <template #forgotPassword>
      <span
        class="c-text-hyperlink ui-cursor-pointer c-text-gray"
        @click="activeForm = 'forgot-password'"
      >
        Esqueci minha senha
      </span>
    </template>
  </PLogin>
  <PEsqueciSenha
    v-else-if="activeForm === 'forgot-password'"
    :auth-popover="true"
    @recovery-password="handleRecoveryPasswordSent"
  >
    <template #return>
      <c-button
        outline
        class="ui-w-full"
        data-cy="registerButton"
        size="lg"
        @click="activeForm = 'login'"
      >
        Voltar
      </c-button>
    </template>
  </PEsqueciSenha>
  <PTrialUserForm
    v-else-if="activeForm === 'create-form'"
    @created-user="handleCreatedUser"
    @logged-in="handleLoggedIn"
  >
    <template #login>
      <span
        class="c-text-hyperlink ui-cursor-pointer"
        @click="activeForm = 'login'"
      >
        Acesse aqui
      </span>
    </template>
  </PTrialUserForm>
</template>

<script>
import PLogin from '@/components/p-login/index.vue';
import PEsqueciSenha from '@/components/p-esqueci-senha/index.vue';
import PTrialUserForm from '@/components/p-trial-user-form/index.vue';
import { requestCanceledError } from '@/utils/errors';
import { CButton } from '@estrategiahq/coruja-web-ui/dist/coruja-web-ui.cjs';
import { ALLOWED_POST_MESSAGE_ORIGINS } from '@/consts';

export default {
  name: 'AuthPopup',
  components: {
    PLogin,
    PTrialUserForm,
    PEsqueciSenha,
    CButton,
  },
  data: () => {
    return {
      activeForm: 'create-form',
      goalId: null,
      frontStudentUrl: null,
      validForms: ['login', 'create-form', 'forgot-password'],
    };
  },
  async mounted() {
    if (!window.opener && window.top === window.self && !document.referrer) {
      this.$router.push({
        name: 'login',
      });
    }
    const activeForm = this.$route.query.active_form || null;
    if (activeForm && this.validForms.includes(activeForm)) {
      this.activeForm = activeForm;
    }

    if (this.$route.query.objetivo_id) {
      if (this.$route.query.target) {
        this.goalId = this.$route.query.objetivo_id;

        this.frontStudentUrl = this.$vertical.getUrl(this.$route.query.target);
      } else {
        this.$toast.show({
          type: 'error',
          text: 'Redirecionamento Inválido',
          timeout: 0,
        });
      }
    }

  },
  methods: {
    handleLoggedIn(logindata) {
      this.sendDataToWindowOpener(logindata);
    },
    async handleCreatedUser(user) {
      try {
        const logindata = await this.$services.accounts.login(user.email, user.password);
        this.sendDataToWindowOpener(logindata);
      } catch (e) {
        if (e === requestCanceledError) {
          return;
        }
        console.error(e);
      }
    },
    sendDataToWindowOpener(data) {
      const origins = [...ALLOWED_POST_MESSAGE_ORIGINS[process.env.VUE_APP_ENVIRONMENT], ...ALLOWED_POST_MESSAGE_ORIGINS.local];
      const validOrigin = origins?.find(o => new URL(document.referrer).origin === o) || '';
      if (data?.email && data?.token) {
        // Enviar os dados para a janela de origem
        if (window.opener) {
          window.opener.postMessage({
            loggedIn: true,
            ...data,
          }, validOrigin);

        } else {
          window.top.postMessage({
            loggedIn: true,
            ...data,
          }, validOrigin);
        }

        if (this.goalId) {
          this.createTrialAccessByGoalId();
        } else {
          // Fechar a popup
          window.close();
        }

      } else {
        this.$toast.show({
          type: 'error',
          text: 'Erro ao acessar dados do usuário! Realize login novamente.',
          timeout: 3000,
        });
        this.activeForm = 'login';
      }
    },
    handleRecoveryPasswordSent() {
      this.$toast.show({
        type: 'success',
        text: 'Se o email estiver cadastrado, você receberá um link para redefinir a senha.',
        timeout: 3000,
      });
      this.activeForm = 'login';
    },
    async createTrialAccessByGoalId() {
      try {
        await this.$services.bff.createTrialAccessByGoalId(this.goalId);

        window.resizeTo(screen.width, screen.height);
        window.location.href = this.frontStudentUrl;
      }
      catch (e) {
        this.$toast.show({
          type: 'warning',
          text: 'Erro ao criar acesso. Tente novamente.',
          timeout: 3000,
        });
      }
    },
  },
};
</script>
